import React from 'react'
import Home from "./HOME/Home"
import Contact from "./CONTACT/Contact"
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Impressum from "./IMPRESSUM/Impressum"
import Datenschutz from "./DATENSCHUTZ/Datenschutz"
import WidgetWatch from "./WIDGETWATCH/widgetwatch"
import StickerQRCode from "./STICKER/stickerQRCode"


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/index.html' element={<Home/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/datenschutz' element={<Datenschutz/>} />
          <Route path='/impressum' element={<Impressum/>} />
          <Route path='/widgetwatch' element={<WidgetWatch/>} />
          <Route path='/sticker/qr' element={<StickerQRCode/>} />
          {/* <Route path="/" component={Home} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/datenschutz" exact component={Datenschutz} />
          <Route path="/impressum" exact component={Impressum} />
          <Route path="/widgetwatch" exact component={WidgetWatch} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
