import React, {useEffect, useState} from 'react'
import Delayed from '../Delayed';
import "./stickerQRCode.css"


function StickerQRCode() {

	const [urlString, setUrlString] = useState("https://www.instagram.com/HONKInternational/");

	useEffect(() => {
		fetch('https://test-deep-sale-v2.herokuapp.com/qr-sticker')
			.then(response => response.json())
			.then(data => {
				redirectToUrl(data)
			})
			.catch(error => {
				// redirectToUrl("https://www.instagram.com/HONKInternational/")
				console.error("error:", error)
			})
		
	}, [])

	const redirectToUrl = (urlString) => {
		if (urlString === "noredirect") {
			setUrlString("https://www.instagram.com/HONKInternational/")
			//setUrlString("https://apps.apple.com/de/app/reel-story-maker-aora/id6737612250")
			return
		}

		if (isValidHttpUrl(urlString)) {
			setUrlString(urlString)
			//window.location = urlString
			window.location.href = urlString
		} else {
			window.location.href = "https://www.instagram.com/HONKInternational/"
		}
	}

	const isValidHttpUrl = (string) => {
		let url
		try {
		  url = new URL(string)
		} catch (_) {
		  return false
		}
		return url.protocol === "http:" || url.protocol === "https:"
	  }

	return (
	<div className="StickerQRCode">
		<Delayed waitBeforeShow={2000}>
			<div className='donotclick-container'>
				<a href={`${urlString}`}>Do not click me!</a>
			</div>
		</Delayed>
	</div>
	);
  }
  
  export default StickerQRCode;