import React from 'react'
import "./Home.css"
import Header from "../PUBLIC/Header"
import Footer from "../PUBLIC/Footer"


function Home() {
	return (
	  <div className="Home">
			<Header title="APPS"/>

			<div className="app-container">
				<div className="iphone-image" id="image-aora"></div>
				<div className="app-info">
					<h3 id="h3-app-2">Aora</h3>
					<p className="info">Create stunning Stories and Reels effortlessly! Unlock professional templates, dynamic animations, and eye-catching designs to elevate your social media content.</p>
					<a className="app-store" id="as-app-2" href="https://apps.apple.com/us/app/reel-story-maker-aora/id6737612250"></a>
				</div>
			</div>

			<div className="app-container">
				<div className="iphone-image" id="image-namir"></div>
				<div className="app-info">
					<h3 id="h3-app-2">NAMIR</h3>
					<p className="info">Shop over 1,000 brands and find exactly what you want.</p>
					<a className="app-store" id="as-app-2" href="https://apps.apple.com/de/app/namir/id1659295509"></a>
				</div>
			</div>

			<div className="app-container">
				<div className="iphone-image" id="image-inStockTrain"></div>
				<div className="app-info">
					<h3 id="h3-app-2">IN STOCK TRAIN</h3>
					<p className="info">PS5 & Xbox Sereis X Stock Tracker, latest gaming deals and releases. All in one App.</p>
					<a className="app-store" id="as-app-2" href="https://apps.apple.com/de/app/in-stock-train/id1553102003"></a>
				</div>
			</div>

			<div className="app-container">
				<div className="iphone-image" id="image-widgetwatch"></div>
				<div className="app-info">
					<h3 id="widgetwatch-title">WIDGETWTACH</h3>
					<p className="info" id="widgetwatch-info">Place the unique watches from WidgetWatch directly on your home screen.</p>
					<a className="app-store" id="widgetwatch-info-bandge" href="https://apps.apple.com/us/app/widgetwatch-clock-widgets/id1535214658" target="_blank" rel="noopener noreferrer"></a>
				</div>
			</div>

			<div className="app-container">
				<div className="iphone-image" id="image-deepsale"></div>
				<div className="app-info">
					<h3><span>DEEP</span> SALE</h3>
					<p className="info">DISCOVER THE BEST DEALS FOR DESIGNER CLOTHES</p>
					<a className="visit" href="https://deep-sale.com/" target="_blank" rel="noopener noreferrer">
						<p className="v">VISIT</p> <p className="brand">DEEP <span>SALE</span></p>
					</a>
					<a className="app-store" href="https://apps.apple.com/app/deep-sale/id1498228431"></a>
				</div>
			</div>

			<div className="app-container" id="app-container-2">
				<div className="iphone-image" id="image-where"></div>
				<div className="app-info">
					<h3 id="h3-app-2">WHERE ARE THE HIMOJIS</h3>
					<p className="info">CAN YOU SPOT THE HIMOJIS ?</p>
					<a className="app-store" id="as-app-2" href="https://apps.apple.com/us/app/where-are-the-himojis/id1434249374"></a>
				</div>
			</div>
			

			{/* Footer */}
			<Footer />
	  </div>
	);
  }
  
  export default Home;
  